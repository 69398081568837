<template>
	<div>
		<div id="login-div" class="login-button"></div>
		<!-- <p>ual login</p> -->
	</div>
</template>

<script>
///import { UALJs } from "ual-plainjs-renderer";
import axios from "axios";
import { Anchor } from "ual-anchor";
import { Wax } from "@eosdacio/ual-wax";
import WaxService from "../core/services/waxjs-service.js";
import { ASSETTYPE } from "../core/models/constants";
import { Session, SessionKit } from "@wharfkit/session";
import { WebRenderer } from "@wharfkit/web-renderer";
import { WalletPluginCloudWallet } from "@wharfkit/wallet-plugin-cloudwallet";
import { WalletPluginAnchor } from "@wharfkit/wallet-plugin-anchor";
import { WalletPluginWombat } from "@wharfkit/wallet-plugin-wombat";

var waxMainnet = {
	chainId: "",
	rpcEndpoints: [
		{
			protocol: "https",
			host: "",
			port: 443,
		},
	],
};

export default {
	name: "Uallogin",
	data() {
		return {
			waxAccountName: "",
			//anchor: new Anchor([waxMainnet], { appName: "giftnft" }),
			//wax: new Wax([waxMainnet]),
			anchor: null,
			wax: null,
			ual: null,
			arrayofUsers: [],
			authUser: null,
			view: null,
			waxPermission: "active",
			isAdmin: false,
			sessionKit: SessionKit,
			session: Session,
		};
	},
	methods: {
		async login(view) {
			this.view = view;
			if (this.view == "Claim2" || this.view == "IframeClaim2") {
				let waxService = new WaxService();
				this.waxAccountName = await waxService.login();
				this.$emit("update-accountname", this.waxAccountName);
			} else {
				if (this.waxAccountName) {
					this.$emit("update-accountname", this.waxAccountName);
					if (view && this.$route.name != "Gift") {
						//this.$router.push(this.view);
						this.$router.push({
							path: this.view,
							query: this.$route.query,
						});
					}
				} else {
					//var elem = document.getElementById("ual-button");
					///elem.click();
					var response = await this.sessionKit.login();
					this.session = response.session;
					this.onWharfkitLogin(this.session);
				}
			}
		},
		getWaxAccountName() {
			this.$emit("update-accountname", this.waxAccountName);
		},
		async logout() {
			this.waxAccountName = "";
			await this.sessionKit.logout();
			this.session = null;
			this.$emit("update-accountname", this.waxAccountName);
			localStorage.removeItem("ual-session-expiration");
			localStorage.removeItem("userGuid");
			localStorage.removeItem("isTwitterConnected");
			localStorage.removeItem("twitterScreenName");
			localStorage.setItem("ual-wax:autologin", null);
			if (this.$route.name == "Gift" || this.$route.name == "Emails") {
				this.$router.push({
					path: `/`,
					query: { id: this.$route.query.id },
				});
			}
		},
		async transact(data) {
			const actions = this.getTransactionActions(data);
			let result = await this.authUser.transact(
				{ actions: actions },
				{ blocksBehind: 3, expireSeconds: 1200, broadcast: true }
			);
			if (result) {
				return result.response.transaction_id;
			}
		},

		async transactAnnounceLink(data) {
			const actions = this.getAnnounceLinkActions(data.keys, data.assets);
			let result = await this.authUser.transact(
				{ actions: actions },
				{ blocksBehind: 3, expireSeconds: 1200, broadcast: true }
			);
			if (result) {
				return result.response.transaction_id;
			}
		},

		getAnnounceLinkActions(keys, assetIDs) {
			let actions = [];
			//console.log(keys);
			//console.log(assetIDs);
			assetIDs.map((ele, index) => {
				if (keys[index]) {
					actions.push(
						{
							account: "atomictoolsx",
							name: "announcelink",
							authorization: [
								{
									actor: this.waxAccountName,
									permission: this.waxPermission, // add permission
								},
							],
							data: {
								creator: this.waxAccountName,
								key: keys[index].publicKey,
								asset_ids: [ele.AssetID.toString()],
								memo: "",
							},
						},
						{
							account: "atomicassets",
							name: "transfer",
							authorization: [
								{
									actor: this.waxAccountName,
									permission: this.waxPermission,
								},
							],
							data: {
								from: this.waxAccountName,
								to: "atomictoolsx",
								asset_ids: [ele.AssetID.toString()],
								memo: "link",
							},
						}
					);
				}
			});
			return actions;
		},

		async transactClaimLink(data) {
			const actions = this.getClaimLinkActions(data);
			let result = await this.authUser.transact(
				{ actions: actions },
				{ blocksBehind: 3, expireSeconds: 1200, broadcast: true }
			);
			if (result) {
				return result.transactionId;
			}
		},

		getClaimLinkActions(data) {
			console.log(data);
			let actions = [];
			actions.push({
				account: "atomictoolsx",
				name: "claimlink",
				authorization: [
					{
						actor: this.waxAccountName,
						permission: this.waxPermission,
					},
				],
				data: {
					link_id: data.linkID,
					claimer: this.waxAccountName,
					claimer_signature: data.claimerSignature,
				},
			});

			return actions;
		},

		async transactCancelLink(data) {
			const actions = this.getCancelLinkActions(data);
			let result = await this.authUser.transact(
				{ actions: actions },
				{ blocksBehind: 3, expireSeconds: 1200, broadcast: true }
			);
			if (result) {
				return result.response.transaction_id;
			}
		},

		getCancelLinkActions(data) {
			console.log(data);
			let actions = [];
			actions.push({
				account: "atomictoolsx",
				name: "cancellink",
				authorization: [
					{
						actor: this.waxAccountName,
						permission: this.waxPermission,
					},
				],
				data: {
					link_id: data.linkID,
				},
			});

			return actions;
		},

		async transactV2(actions) {
			return await this.authUser.signTransaction(
				{ actions: actions },
				{ blocksBehind: 3, expireSeconds: 1200, broadcast: true }
			);
		},
		getTransactionActions(data) {
			let actions = [];
			if (data.assetType == ASSETTYPE.AA) {
				data.assets.forEach((e) => {
					actions.push({
						account: "atomicassets",
						name: "transfer",
						authorization: [
							{
								actor: this.waxAccountName,
								permission: this.waxPermission,
							},
						],
						data: {
							from: this.waxAccountName,
							to: `${process.env.VUE_APP_WAX_ACCOUNT}`,
							asset_ids: [`${e.AssetID}`],
							memo: "giftnft",
						},
					});
				});
			} else {
				data.assets.forEach((e) => {
					actions.push({
						account: "simpleassets",
						name: "transfer",
						authorization: [
							{
								actor: this.waxAccountName,
								permission: this.waxPermission,
							},
						],
						data: {
							from: this.waxAccountName,
							to: `${process.env.VUE_APP_WAX_ACCOUNT}`,
							assetids: [`${e.AssetID}`],
							memo: "",
						},
					});
				});
			}

			return actions;
		},
		onWharfkitLogin(session) {
			this.waxAccountName = session.actor.toString();
			this.authUser = this.session;
			this.waxPermission = session.permission.toString();
			console.log(session)
			session.client.v1.chain.get_account(this.waxAccountName).then(res => {
			var availableRam = ((res.ram_quota) / 1024).toFixed(2)
			var ramUsed = ((res.ram_usage) / 1024).toFixed(2)
			var ramUsagePercentage = Math.round(((res.ram_usage ) / (res.ram_quota)) * 100)
			var resourcesObj = {
				availableRam : availableRam,
				ramUsagePercentage : ramUsagePercentage,
				ramUsed : ramUsed,
			}
			this.$emit("update-resources", resourcesObj);
			},
			err => {
				console.log(err)
			})
			// authenticate user on our server
			axios
				.post(`${process.env.VUE_APP_BASE_API_URL}members/authenticate`, {
					username: this.waxAccountName,
				})
				.then((response) => {
					if (response.status == 200) {
						localStorage.setItem("userGuid", response.data.userGuid);
						localStorage.setItem(
							"isTwitterConnected",
							response.data.isTwitterConnected
						);
						localStorage.setItem(
							"isSendgridConnected",
							response.data.isSendgridConnected
						);
						localStorage.setItem(
							"twitterScreenName",
							response.data.twitterScreenName
						);
						this.$emit("update-accountname", this.waxAccountName);
						this.$emit("update-waxPermission", this.waxPermission);
						this.$emit("update-isAdmin", response.data.isAdmin);

						if (this.$route.query.returnUrl) {
							this.$router.push(this.$route.query.returnUrl);
						} else if (this.view) {
							this.$router.push(this.view);
						}
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},
	},
	async mounted() {
		const chainData = await axios.get(
			`${process.env.VUE_APP_BASE_API_URL}members/chaindata`
		);

		waxMainnet = {
			chainId: chainData.data.chainID,
			rpcEndpoints: [
				{
					protocol: "https",
					host: chainData.data.chainEndpoint,
					port: 443,
				},
			],
		};

		this.sessionKit = new SessionKit({
			walletPlugins: [
				new WalletPluginCloudWallet(),
				new WalletPluginAnchor(),
				new WalletPluginWombat(),
			],
			appName: "bountyblok",
			chains: [
				{
					id: chainData.data.chainID,
					url: chainData.data.chainEndpoint,
				},
			],
			ui: new WebRenderer(),
		});

		this.session = await this.sessionKit.restore();
    if(this.session){
      this.onWharfkitLogin(this.session);
    }
		

		let myAppName = "Gift NFT";
		this.anchor = new Anchor([waxMainnet], { appName: myAppName });
		this.wax = new Wax([waxMainnet]);

		// let myAppRoot = {
		//   containerElement: document.getElementById("login-div"),
		// };

		let authenticators = [];

		if (this.$route.name != "Claim2") {
			authenticators.push(this.anchor);
		}
		// authenticators.push(this.wax);
		// this.ual = new UALJs(
		//   (arrayofUsers) => {
		//     if (arrayofUsers.length > 0) {
		//         if(arrayofUsers[0].session && arrayofUsers[0].session.auth.permission)
		//         {
		//             this.waxPermission = arrayofUsers[0].session.auth.permission;
		//             } else {
		//                 this.waxPermission = "active";
		//             }
		//             console.log(arrayofUsers[0])
		//             console.log(this.wax)
		//       this.waxAccountName = arrayofUsers[0].accountName;
		//       this.authUser = arrayofUsers[0];

		//       // authenticate user on our server
		//       axios
		//         .post(`${process.env.VUE_APP_BASE_API_URL}members/authenticate`, {
		//           username: this.waxAccountName,
		//         })
		//         .then((response) => {
		//           if (response.status == 200) {
		//             localStorage.setItem("userGuid", response.data.userGuid);
		//             localStorage.setItem("isTwitterConnected", response.data.isTwitterConnected)
		//             localStorage.setItem("isSendgridConnected", response.data.isSendgridConnected)
		//             localStorage.setItem("twitterScreenName", response.data.twitterScreenName)
		//             this.$emit(
		//               "update-accountname",
		//               arrayofUsers[0].accountName
		//             );
		//             this.$emit('update-waxPermission',  arrayofUsers[0].requestPermission)
		//             this.$emit('update-isAdmin', response.data.isAdmin)

		//             if (this.$route.query.returnUrl) {
		//               this.$router.push(this.$route.query.returnUrl);
		//             } else if (this.view) {
		//               this.$router.push(this.view);
		//             }
		//           }
		//         })
		//         .catch(function (error) {
		//           console.log(error);
		//         });
		//     }
		//   },
		//   [waxMainnet],
		//   myAppName,
		//   authenticators,
		//   myAppRoot
		// );

		//this.ual.init();
		//document.getElementById("ual-button").style.display = "none";
	},
};
</script>

// <style scoped lang="scss">
// #ual-modal {
//   z-index: 9999;
// }
//
</style>
